var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"devis"},[_c('v-container',{staticClass:"my-5"},[_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v(" Devis "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.$tableHeaders.quotes,"items":_vm.quotes,"search":_vm.search,"item-key":_vm.quotes.id,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]},proxy:true},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(item.customerDetails)?[(item.customerDetails.firstName)?_c('v-chip',{attrs:{"to":{path: 'record/customer/' + item.customer}}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"contain":"","src":_vm.$functions.getImgLink(item.customerDetails.avatar, 50)}})],1),_vm._v(" "+_vm._s(item.customerDetails.lastName.toUpperCase() + " " + item.customerDetails.firstName)+" ")],1):_c('v-chip',{attrs:{"to":{path: 'record/company/' + item.customer}}},[(item.customerDetails.avatar)?_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"contain":"","src":_vm.$functions.getImgLink(item.customerDetails.avatar, 50)}})],1):_vm._e(),_vm._v(" "+_vm._s(item.customerDetails.name)+" ")],1)]:_vm._e()]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.value.toLocaleString(undefined, { minimumFractionDigits: 2}))+"€ ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.$cssGlobal.getStatus(_vm.$listUtils.quoteStatus[item.status], 'quote'),"outlined":"","fab":"","dark":"","height":"15px","width":"15px","center":""}},[_vm._v(_vm._s(_vm.$listUtils.quoteStatus[item.status]))])]}},{key:"item.manage",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":"","to":{path: 'record/quote/' + item.id}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye")])],1),_c('v-btn',{attrs:{"text":"","icon":""}},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDeleteDialog(item.id, item.nQuote)}}},[_vm._v("mdi-delete")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.$tableHeaders.quotes.length}},[_c('div',{staticClass:"row sp-details"},[_c('div',{staticClass:"col-12"},[_c('v-card-title',[_vm._v("Devis n°"+_vm._s(item.nQuote)+" • Valeur : "+_vm._s(item.value)+"€ ")]),_c('v-card-subtitle',[_vm._v("Référence : "+_vm._s(item.reference))]),_c('v-card-text',{staticClass:"text--primary"},[_c('div',[_vm._v(_vm._s(item.remark))])])],1)])])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"900","fullscreen":""},model:{value:(_vm.dialogDeleteItem),callback:function ($$v) {_vm.dialogDeleteItem=$$v},expression:"dialogDeleteItem"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Êtes-vous certains de vouloir supprimer ce devis ?")]),_c('v-card-text',[_vm._v("Toutes les informations relative à ce devis seras retiré de la base de donnée. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('v-btn',{staticClass:"text-center",attrs:{"color":"success darken-1","outlined":""},on:{"click":function($event){_vm.dialogDeleteItem = false, _vm.confirmDelete = false}}},[_vm._v("Non, je ne souhaite pas supprimer ce devis")])],1),_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('v-btn',{staticClass:"text-center",attrs:{"color":"error darken-1","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.deleteQuote(_vm.identifiant, _vm.delCompName)}}},[_vm._v("Oui, je souhaite supprimer ce devis")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }