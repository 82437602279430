<template>
	<div class="devis">

		<v-container class="my-5">
			<v-card class="mt-5">
				<v-card-title>
					Devis
					<v-spacer></v-spacer>
					<v-text-field v-model="search" append-icon="mdi-magnify" label="Rechercher" single-line>
					</v-text-field>
				</v-card-title>

				<v-data-table :headers="$tableHeaders.quotes" :items="quotes" :search="search" :item-key="quotes.id"
					:single-expand="singleExpand" :expanded.sync="expanded" show-expand>

					<template v-slot:placeholder>
						<v-row class="fill-height ma-0" align="center" justify="center">
							<v-progress-circular indeterminate color="primary"></v-progress-circular>
						</v-row>
					</template>

					<template v-slot:[`item.customer`]="{ item }">
						<template v-if="item.customerDetails">
							<!-- v-bind="data.attrs" :input-value="data.selected" @click="data.select" -->
							<v-chip :to="{path: 'record/customer/' + item.customer}" v-if="item.customerDetails.firstName">
								<v-avatar left>
									<v-img contain :src="$functions.getImgLink(item.customerDetails.avatar, 50)"></v-img>
								</v-avatar>
								{{ item.customerDetails.lastName.toUpperCase() + " " + item.customerDetails.firstName }}
							</v-chip>
							<v-chip :to="{path: 'record/company/' + item.customer}" v-else>
								<v-avatar left v-if="item.customerDetails.avatar">
									<v-img contain :src="$functions.getImgLink(item.customerDetails.avatar, 50)"></v-img>
								</v-avatar>
								{{ item.customerDetails.name }}
							</v-chip>
						</template>
					</template>

					<template v-slot:[`item.value`]="{ item }">
						{{ item.value.toLocaleString(undefined, { minimumFractionDigits: 2}) }}€
					</template>

					<template v-slot:[`item.status`]="{ item }">
						<v-chip :color="$cssGlobal.getStatus($listUtils.quoteStatus[item.status], 'quote')" outlined fab dark height="15px" width="15px" center>{{ $listUtils.quoteStatus[item.status] }}</v-chip>
					</template>

					<template v-slot:[`item.manage`]="{ item }">
						<v-btn text icon :to="{path: 'record/quote/' + item.id}">
							<v-icon color="primary">mdi-eye</v-icon>
						</v-btn>
						<v-btn text icon>
							<v-icon color="primary" @click="openDeleteDialog(item.id, item.nQuote)">mdi-delete</v-icon>
						</v-btn>
					</template>

					<template v-slot:expanded-item="{ headers, item }">
						<td :colspan="$tableHeaders.quotes.length">
							<div class="row sp-details">
								<div class="col-12">
									<v-card-title>Devis n°{{ item.nQuote }} • Valeur : {{ item.value }}€
									</v-card-title>
									<v-card-subtitle>Référence : {{ item.reference }}</v-card-subtitle>
									<v-card-text class="text--primary">
										<div>{{ item.remark }}</div>
									</v-card-text>
								</div>
							</div>
						</td>
					</template>
				</v-data-table>
			</v-card>
		</v-container>


		<v-dialog v-model="dialogDeleteItem" max-width="900" fullscreen>
			<v-card>
				<v-card-title class="headline">Êtes-vous certains de vouloir supprimer ce devis ?</v-card-title>

				<v-card-text>Toutes les informations relative à ce devis seras retiré de la base de donnée.
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-row align="center" justify="center">
						<v-col cols="12" align="center" justify="center">
							<v-btn color="success darken-1" outlined class="text-center"
								@click="dialogDeleteItem = false, confirmDelete = false">Non, je ne souhaite pas
								supprimer ce devis</v-btn>
						</v-col>

						<v-col cols="12" align="center" justify="center">
							<v-btn color="error darken-1" class="text-center" :loading="loading" :disabled="loading"
								@click="deleteQuote(identifiant, delCompName)">Oui, je souhaite supprimer ce devis</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>


	</div>
</template>


<style>
	#blurMe {
		filter: blur(10px);
		-webkit-mask-image: -webkit-gradient(linear,
				left top,
				left bottom,
				from(rgba(0, 0, 0, 1)),
				to(rgba(0, 0, 0, 0)));
	}

	#lateral {
		position: fixed;
		top: 15px;
		right: 15px;
		margin: 0 0 16px 16px;
	}

	#create .v-speed-dial {
		position: absolute;
	}

	#create .v-btn--floating {
		position: relative;
	}
</style>


<script>
	export default {
		props: {
			'id': {
				type: String
			}
		},
		components: {

		},
		data() {
			return {
				dialog: false,
				customer: {},
				company: {},

				loader: null,
				loading: false,
				dialogDeleteItem: false,
				identifiant: null,
				expanded: [],
				singleExpand: false,
				search: "",

				viewCustom: false,
				modifiedCustom: "",

				delCompName: '',

				quotes: [],
				
				userInfo: this.$models.user,

				customerEntry: this.$models.user,
				customersProject: {}
			};
		},

		watch: {
			loader() {
				const l = this.loader;
				this[l] = !this[l];

				setTimeout(() => (this[l] = false), 3000);

				this.loader = null;
			},
		},

		methods: {
			openDeleteDialog(id, name) {
				this.dialogDeleteItem = true,

				this.identifiant = id;
				this.delCompName = name;
			},

			deleteQuote(id, idQuote) {
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection("quotes").doc(id), `Le devis n°${idQuote} a été supprimé !`);
				this.dialogDeleteItem = false;
			}
		},

		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = doc.data();

				var companyRef = this.$db.collection("companies").doc(this.userInfo.company);

				this.$db.collection("companies").doc(doc.data().company).collection("quotes").onSnapshot((res) => {
					this.quotes = [];

					res.forEach((document) => {
						companyRef.collection("customers").doc(document.data().customer).onSnapshot(docCus => {
							companyRef.collection("companies").doc(document.data().customer).get().then(docComp => {
								var temp = docCus.data() || docComp.data();

								this.quotes.push({
									...document.data(),
									id: document.id,
									customerDetails: temp
								});
							});
						});
					});
				});
			});
		}
	};
</script>