<template>
	<v-row justify="center">
		<v-dialog :value="value" @input="$emit('input')" max-width="600px" hide-overlay
			transition="dialog-bottom-transition">
			<v-card>
				<v-card-title>
					<span class="headline">Créer un devis</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form class="form">

							<v-autocomplete clearable v-model="selectPeople" :items="people" chips deletable-chips
								color="blue-grey lighten-2" label="Client" item-text="id" item-value="id">
								<template v-slot:selection="data">
									<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select" close>
										<v-avatar left>
											<v-img contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
										</v-avatar>
										{{ data.item.name }}
									</v-chip>
								</template>
								<template v-slot:item="data">
									<template v-if="data.header">
										<v-list-item-content v-text="data.item"></v-list-item-content>
									</template>
									<template v-else>
										<v-list-item-avatar>
											<v-img contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
										</v-list-item-avatar>
										<v-list-item-content>
											<!-- v-html="data.item.name" -->
											<v-list-item-title>{{ data.item.name }}</v-list-item-title>
											<v-list-item-subtitle
												v-html="'Numéro ' + data.item.denom + ' : ' + data.item.content">
											</v-list-item-subtitle>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>

						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click.native="$emit('input', false)">Annuler</v-btn>
					<v-btn class="ma-2 mr-10" outlined color="success" @click.native="$emit('input', false)"
						@click="submit">Ajouter</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
	export default {
		props: {
			'value': {},
			'id': {
				type: String
			}
		},
		data() {
			return {
				people: [],
				selectPeople: ''
			}
		},
		methods: {
			submit() {
				var currentMonth = new Date().getMonth();
				var dueDate = new Date().setMonth(currentMonth + 1);
				
				const devis = {
					'creationDate': new Date(),
					'dueDate': new Date(dueDate),
					'nQuote': this.upQuote,

					'customer': this.selectPeople,

					'status': 0,

					'discount': {
						'label': '',
						'value': 0
					},
					'wage': 0,
					'value': 0,

					'reference': Math.random().toString(36).substring(7).toUpperCase(),

					'remark': '',
				};

				this.$db.collection("companies").doc(this.userInfo.company).collection("quotes").add(devis).then((docRef) => {
					this.$store.dispatch('setSnackbar', {
						text: `Le devis n°${this.upQuote} (Ref: ${devis.reference}) a été ajouté !`,
					});

					this.$router.replace({
						path: "record/quote/" + docRef.id
					});
				});
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = doc.data();

				this.$db.collection("companies").doc(this.userInfo.company).collection("quotes").onSnapshot((res) => {
					this.quotes = [];
					this.numbers = [];

					res.forEach((document) => {
						this.quotes.push({
							...document.data(),
							id: document.id,
						});

						this.numbers.push(document.data().nQuote);
					});

					this.upQuote = this.quotes.length == 0 ? 1 : Math.max(...this.numbers) + 1;
				});
			});			

			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.people = [{
					header: 'Clients'
				}]

				this.$db.collection("companies").doc(this.userInfo.company).collection("customers").onSnapshot((res) => {
					res.forEach((document) => {
						this.people.push({
							'name': document.data().lastName.toUpperCase() + " " + document
								.data().firstName,
							'content': document.data().nClient,
							'id': document.id,
							'avatar': document.data().avatar,
							'denom': "client"
						});
					});

					this.people.push({
						divider: true
					}, {
						header: 'Entreprises'
					})

					this.$db.collection("companies").doc(doc.data().company).collection("companies").onSnapshot((res) => {
							res.forEach((document) => {
								this.people.push({
									'name': document.data().name,
									'content': document.data().nCompany,
									'id': document.id,
									'avatar': document.data().avatar,
									'denom': "entreprise"
								});
							});
						});
				});
			});
		}
	};
</script>